// React component code including price flip animation
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Container, TextField, Button, Link, Grid, Paper, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import './App.css';

// Register ChartJS components
// ChartJS.register(registerables, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
ChartJS.register(...registerables);

// Styled components for a cleaner UI
const StyledContainer = styled(Container)({
  padding: '20px',
  backgroundColor: '#1F1F1F',
  color: '#fff',
  borderRadius: '10px',
  boxShadow: 'none',
  marginTop: '20px',
  minHeight: '600px',
});

const Flipper = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginLeft: '10px',
  fontSize: '18px',
  height: '24px',
  width: '80px',
  perspective: '1000px'
}));

const Card = styled('div')(({ isFlipped }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  transition: 'transform 0.6s',
  transformStyle: 'preserve-3d',
  transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
}));

const CardFace = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  borderRadius: '4px'
});

const CardFront = styled(CardFace)({
  backgroundColor: '#00c853',
});

const CardBack = styled(CardFace)({
  backgroundColor: '#d32f2f',
  transform: 'rotateY(180deg)',
});

const Dashboard = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <StyledContainer maxWidth="md">
        <Typography variant="h6" gutterBottom align="center" sx={{ color: '#ffff', position: 'relative' }}>
        Dashboard
        </Typography>
      </StyledContainer>
    </Box>
  );
};

export default Dashboard;
