import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, GlobalStyles } from '@mui/material';
import SignUp from './SignUp';
import Unsubscribe from './Unsubscribe';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Disclaimers from './Disclaimers';
import StockChart from './StockChart';
import CryptoChart from './CryptoChart';
import Footer from './Footer';
import Header from './Header';
import Dashboard from './Dashboard';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d92d20',
    },
    background: {
      default: '#292929',  // Dark grey background
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

const App = () => {
  const currentYear = new Date().getFullYear();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{
        body: { overflowX: 'hidden' },
        html: { overflowX: 'hidden' }
      }} />
      <Router>
        <Box
          display="flex"
          flexDirection="column"
          minHeight="100vh"
          // fontSize="12px"
          sx={{
            backgroundColor: '#292929',  // Dark grey background
            color: '#fff',
            padding: '2rem 0', // Add padding on top and bottom
            maxWidth: '100%', // Ensure the container doesn't exceed viewport width
          }}
        >
          {/* <Header /> */}
          <Box flexGrow={1}>
            <Routes>
              <Route path="/" element={<SignUp />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/newsletter" element={<SignUp />} />
              <Route path="/unsubscribe" element={<Unsubscribe />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/disclaimers" element={<Disclaimers />} />
              <Route path="/stocks/:ticker" element={<StockChart />} />
              <Route path="/stocks" element={<StockChart />} />
              <Route path="/crypto" element={<CryptoChart />} />
              <Route path="/crypto/:ticker" element={<CryptoChart />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
};

export default App;
