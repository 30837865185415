import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faDiscord, faBluesky, faThreads, faReddit } from '@fortawesome/free-brands-svg-icons';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Dynamically get the current year

  return (
    <Box component="footer" py={2} textAlign="center" sx={{ backgroundColor: '#292929', color: '#fff' }}>
      
      <Box display="flex" justifyContent="center" mb={1}>
        <Link href="https://x.com/pocket_quant" target="_blank" sx={{ color: '#fff', mx: 1 }}>
          <FontAwesomeIcon icon={faXTwitter} size="1x" />
        </Link>
        <Link href="https://discord.gg/CMEh4rg3Px" target="_blank" sx={{ color: '#fff', mx: 1 }}>
          <FontAwesomeIcon icon={faDiscord} size="1x" />
        </Link>
        {/* <Link href="mailto:business@bottleblue.biz" target="_blank" sx={{ color: '#fff', mx: 1 }}>
          <FontAwesomeIcon icon={faEnvelope} size="1x" />
        </Link> */}
        <Link href="https://bsky.app/profile/pocketquant.bsky.social" target="_blank" sx={{ color: '#fff', mx: 1 }}>
          <FontAwesomeIcon icon={faBluesky} size="1x" />
        </Link>
        <Link href="https://www.threads.net/@pocket_quant" target="_blank" sx={{ color: '#fff', mx: 1 }}>
          <FontAwesomeIcon icon={faThreads} size="1x" />
        </Link>
        {/* <Link href="https://www.reddit.com/r/PocketQuant" target="_blank" sx={{ color: '#fff', mx: 1 }}>
          <FontAwesomeIcon icon={faReddit} size="1x" />
        </Link> */}
      </Box>

      <Typography variant="body2" fontSize="12px">
        Designed by <Link href="https://bottlebluellc.com" target="_blank" sx={{ color: '#d92d20', textDecoration: 'none' }}>Bottle Blue LLC</Link> © {currentYear}. All rights reserved.
      </Typography>

      <Typography variant="body2" fontSize="12px">
        Discord, X, Threads, and Bluesky are trademarks of their respective owners. 
      </Typography>

      <Typography variant="body2" fontSize="12px">
        pocketquant.io is not affiliated with any of these third parties.
      </Typography>


      <Box display="flex" justifyContent="center" mt={1} fontSize="12px">
        <Link component={RouterLink} to="/privacy-policy" target="_blank" sx={{ color: '#d92d20', mx: 1, textDecoration: 'none' }}>
          Privacy Policy
        </Link>
        <Link component={RouterLink} to="/terms-of-service" target="_blank" sx={{ color: '#d92d20', mx: 1, textDecoration: 'none' }}>
          Terms of Service
        </Link>
        <Link component={RouterLink} to="/disclaimers" target="_blank" sx={{ color: '#d92d20', mx: 1, textDecoration: 'none' }}>
          Disclaimers
        </Link>
        <Link href="mailto:business@bottleblue.biz" target="_blank" sx={{ color: '#d92d20', mx: 1, textDecoration: 'none' }}>
          Contact
        </Link>
      </Box>

      <Box display="flex" justifyContent="center" mt={1} fontSize="12px">
        Powered by
        <Link component={RouterLink} to="https://docs.bottlebluellc.com/" target="_blank" sx={{ color: '#d92d20', mx: 1, textDecoration: 'none', marginLeft: '4px' }}>
          Bottle Blue API
        </Link>
      </Box>
      
    </Box>
  );
};

export default Footer;
